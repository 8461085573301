"use server";

import { Coins, V4HomeResponse } from "~/data/v4_homepage";
import {
  fetchV4Home,
  getCategories,
  getClientConfig,
  getProductSearch,
  getUserProfile,
} from "../apis/server_apis";
import { getStaticJson } from "../static";
import { SDKInitParams } from "../types/auth";
import { CategoryResult } from "../types/category";
import { ClientConfig } from "../types/client";
import { Product, SearchResults } from "../types/search";
import { HomePageSectionSeparator, Separator } from "../types/separator";
import { UserProfile } from "../types/user";
import { getRequestEvent } from "solid-js/web";
import { Mode, Optional } from "~/types";
import { redirect } from "@solidjs/router";

export type HomePageRouteData = {
  categories: CategoryResult;
  popularBrands: SearchResults;
  userProfile?: UserProfile;
  clientConfig?: ClientConfig;
  sdkMetaData?: SDKMetaData;
  isLoggedIn: boolean;
  coins?: Coins;
  name?: Optional<string>;
  brandList: (BrandListData | SectionData)[];
  mode: Mode;
  allBrands: SearchResults;
  footNotes: { content: string };
};

export type SectionData = {
  title: string;
  bgColor: string;
  brandList?: (Product | undefined)[];
};

export type BrandListData = {
  showAllBrandsTitle: boolean;
  showGiftsSection: boolean;
  brands: Product[];
};

type SDKMetaData = {
  isSDK: boolean;
  sid: string | undefined;
};

export const getHomePageRouteData = async (
  sdkInitParams: Partial<SDKInitParams>
): Promise<HomePageRouteData> => {
  let clientConfig$P: Promise<ClientConfig> | undefined;
  let userProfile$P: Promise<UserProfile> | undefined;
  let v4HomePage$P: Promise<V4HomeResponse> | undefined;
  let allBrands$P: Promise<SearchResults> | undefined;

  const requestEvent = getRequestEvent();
  const mode = requestEvent?.locals.mode ?? "hubble";
  const isSessionPresent = requestEvent?.locals.sid;

  if (sdkInitParams.clientId) {
    clientConfig$P = getClientConfig(sdkInitParams.clientId);
    userProfile$P = getUserProfile();
  } else if (mode === "rnr" && requestEvent?.locals.clientId) {
    clientConfig$P = getClientConfig(requestEvent.locals.clientId);
    v4HomePage$P = fetchV4Home();
    allBrands$P = getProductSearch({
      query: "",
    });
  } else if (mode === "hubble") {
    if (isSessionPresent) {
      v4HomePage$P = fetchV4Home();
    }

    allBrands$P = getProductSearch({
      query: "",
      pagination: {
        limit: 100,
        pageNo: 0,
      },
    });
  } else if (mode === "procurement") {
    throw redirect("/login");
  }

  const categories$P = getCategories();
  const popularBrands$P = getProductSearch({ category: "POPULAR_BRANDS" });

  const getHomePageSectionSeparator$P = getStaticJson(
    "home_page/home_separators.json"
  );
  const footNotesContent$P = getStaticJson("home_page/footnotes.json");

  const resolved = await Promise.all([
    categories$P,
    popularBrands$P,
    allBrands$P,
    userProfile$P ?? Promise.resolve(undefined),
    clientConfig$P ?? Promise.resolve(undefined),
    getHomePageSectionSeparator$P,
    v4HomePage$P,
    footNotesContent$P,
  ]);

  const categories = resolved[0];
  const popularBrands = resolved[1];
  const allBrands = resolved[2];
  const userProfile = resolved[3];
  const clientConfig = resolved[4];
  const homePageSectionSeparator = resolved[5] as HomePageSectionSeparator;
  const v4HomePage = resolved[6];
  const footNotes = resolved[7];

  homePageSectionSeparator.separators.forEach((separator) => {
    separator.products = separator.brands.map((brandKeys) => {
      const product = allBrands?.data?.[0].products.find(
        (product) => product.brandKey === brandKeys
      );
      if (product) {
        return product;
      }
    });
  });

  const brandList = [];
  let brandIndex = 0;
  let sectionIndex = 0;
  const sectionSeparators: Separator[] = [];
  const allBrandList = allBrands?.data[0].products;

  for (let i = 0; i < homePageSectionSeparator.separators.length; i++) {
    const activeProducts =
      homePageSectionSeparator.separators[i].products.filter(Boolean);
    if (activeProducts.length > 3) {
      sectionSeparators.push(homePageSectionSeparator.separators[i]);
    }
  }

  if (allBrands && allBrands.data[0].products) {
    while (brandIndex < allBrands.data[0].products.length) {
      if (sectionIndex < sectionSeparators.length) {
        // //remove undefined values from the array
        const activeProducts = sectionSeparators[sectionIndex].products?.filter(
          (product) => product?.voucherProduct.status === "ACTIVE"
        );
        if (activeProducts.length > 3) {
          const sectionData = {
            title: sectionSeparators[sectionIndex].heading,
            bgColor: sectionSeparators[sectionIndex].color_code,
            brandKeys: sectionSeparators[sectionIndex].brands,
            brandList: activeProducts,
          };
          brandList.push(sectionData);
        }
        sectionIndex++;
      }

      const brandData = {
        showAllBrandsTitle: brandIndex === 0,
        showGiftsSection: false,
        brands: [] as Product[],
      };

      if (sectionIndex < sectionSeparators.length) {
        for (let i = 0; i < 12 && brandIndex < allBrandList!.length; i++) {
          if (brandIndex === 0) {
            brandData.showAllBrandsTitle = true;
            brandData.showGiftsSection = true;
          }
          const product = allBrandList![brandIndex];
          if (product?.voucherProduct.status === "ACTIVE") {
            brandData.brands.push(product);
          }
          brandIndex++;
        }
        brandList.push(brandData);
      } else {
        for (let i = 0; brandIndex < allBrandList!.length; i++) {
          const product = allBrandList![brandIndex];
          if (product?.voucherProduct.status === "ACTIVE") {
            brandData.brands.push(product);
          }
          brandIndex++;
        }
        brandList.push(brandData);
      }
    }
  }

  let routeData = {
    categories,
    popularBrands,
    userProfile,
    clientConfig,
    isLoggedIn: getRequestEvent()?.locals.sid !== undefined,
    coins: v4HomePage?.coins,
    name: v4HomePage?.user?.firstName,
    brandList: brandList,
    mode: mode,
    footNotes,
  } as HomePageRouteData;

  if (sdkInitParams.clientId) {
    routeData = {
      ...routeData,
      sdkMetaData: {
        isSDK:
          clientConfig != undefined && clientConfig?.partnerName !== "hubble",
        sid: getRequestEvent()?.locals.sid,
      },
    };
  }
  return routeData;
};
