import { For } from "solid-js";
import { BrandCard } from "../brand/brand_card";
import { SectionData } from "~/server/data/home_page_route_data";

export type SectionSeparatorProps = SectionData;

export function SectionSeparator(props: SectionSeparatorProps) {
  return (
    <div class="px-4 py-8" style={{ background: "#" + props.bgColor }}>
      <div class="ml-auto mr-auto lg:max-w-[1136px]">
        <div class="flex flex-row items-center pb-5">
          <div class="text-bold text-white lg:text-h4">{props.title}</div>
          <div class="ml-4 h-[1px] grow bg-baseTertiaryDark opacity-10"></div>
        </div>
        <div class="noScrollbar mt-1 grid grid-cols-3 gap-6 gap-x-3 gap-y-6 overflow-auto md:grid-cols-5  md:gap-x-8 lg:grid-cols-6">
          {/* //max 6 brands  */}
          <For each={props.brandList}>
            {(brand) =>
              brand != undefined ? (
                <BrandCard
                  isDark={true}
                  imageUrl={brand.voucherProduct.iconImageUrl}
                  name={brand.voucherProduct.title}
                  categoryName={brand.displayTags[0] || ""}
                  brandKey={brand.brandKey}
                  discountPercentage={brand.voucherProduct.discountPercentage}
                />
              ) : (
                <></>
              )
            }
          </For>
        </div>
      </div>
    </div>
  );
}
