import { A } from "@solidjs/router";
import { HubbleEvent, webEventManager } from "~/data/events";
import { Spacer } from "~/widgets/spacer";

export default function QuickCommerceStrip() {
  return (
    <A
      href="https://rewards.myhubble.money/gift"
      target="_blank"
      onClick={() => {
        webEventManager.sendEvent(HubbleEvent.CLICK_QUICK_COMMERCE_STRIP);
      }}
    >
      <div class="flex cursor-pointer flex-row items-center justify-between bg-[#FCF3EC] px-4 py-3 font-inter md:justify-center ">
        <div class="flex flex-row items-center">
          <NewButton />
          <Spacer width={8} />
          <span class="text-[12px] text-mediumBold text-[#963617] md:hidden">
            Physical gift cards:
            <br /> Get the gift of choice in 10 mins
          </span>
          <span class=" hidden text-[13px] text-mediumBold text-[#963617] md:block">
            Physical gift cards: Get the gift of choice in 10 mins
          </span>
        </div>
        <Spacer width={4} />
        <ArrowRight />
      </div>
    </A>
  );

  function NewButton() {
    return (
      <div
        class="flex items-center px-[6px] py-[5px] md:py-[3px] "
        style={{
          "border-radius": "6px",
          border: "1px solid #FFF",

          background:
            "var(--Gradient-Orange, linear-gradient(135deg, #F90 0%, #FF4016 100%))",
          "box-shadow": "0px 2px 3px 0px rgba(152, 95, 76, 0.13)",
        }}
      >
        <span class="text-f12w700  tracking-[0.96px] text-baseTertiaryLight">
          NEW
        </span>
      </div>
    );
  }

  function ArrowRight() {
    return <i class={"ph-bold ph-arrow-right text-[16px] text-[#963617] "}></i>;
  }
}
