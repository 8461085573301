import { A } from "@solidjs/router";
import { For } from "solid-js";
import { giftsRibbonLg, giftsRibbonSM } from "~/assets/assets";
import { formatGroup } from "~/utils/common";
import occasionsData from "~/data/groups/occasions.json";
import recipientsData from "~/data/groups/recipients.json";

export const GiftsSection = () => {
  return (
    <div class="my-10 bg-gradient-to-r from-[#2a1648] to-[#2a1648] text-white md:mx-auto md:max-w-[1136px] lg:rounded-3xl">
      <div class="relative mx-auto p-6 pr-0 lg:p-8">
        <div class="lg:flex lg:items-end lg:space-x-6">
          <div class="mb-8 w-4/6 lg:mb-0 lg:w-2/5 lg:text-left">
            <div class="hidden lg:block">
              <img src={giftsRibbonLg} alt="" class="absolute left-0 top-0" />
            </div>
            <div class="lg:hidden">
              <img src={giftsRibbonSM} alt="" class="absolute right-0 top-0" />
            </div>
            <h1 class="text-h4 lg:mt-0 lg:text-h1">
              The <span class="italic">gift</span> in Gift cards is
              <div>
                there for a reason <span class="text-3xl lg:text-5xl">😊</span>
              </div>
            </h1>
            <p class="text-medium text-[#9B96BF] lg:mt-3 lg:text-normal">
              Simply purchase & share card details with your loved ones as a
              gift, and they can use it to shop
            </p>
          </div>

          <div class="overflow-x-auto lg:flex lg:w-3/5 lg:justify-end lg:space-x-6 lg:overflow-visible">
            <div class="flex gap-6">
              <div class="shadow-md w-72 flex-shrink-0 rounded-xl bg-white p-6 text-gray-900 lg:w-[308px]">
                <h2 class="mb-4 text-h5 text-textDark">Shop by recipient</h2>
                <div class="flex flex-wrap gap-3">
                  <For each={recipientsData.recipients}>
                    {(recipient) => (
                      <span class="rounded-full border border-baseTertiaryDark px-3  py-1 text-subtitleSmall text-textDark hover:border-basePrimaryDark">
                        <A
                          href={`/buy-gift-cards-for/${formatGroup(recipient)}`}
                        >
                          {recipient}
                        </A>
                      </span>
                    )}
                  </For>
                </div>
              </div>

              <div class="shadow-md  w-72 flex-shrink-0 rounded-xl bg-white p-6 text-gray-900 lg:w-[308px]">
                <h2 class="mb-4 text-h5 text-textDark">Shop by occasion</h2>
                <div class="flex flex-wrap gap-3">
                  <For each={occasionsData.occasions}>
                    {(occasion) => (
                      <span class="rounded-full border border-baseTertiaryDark px-3  py-1 text-subtitleSmall text-textDark hover:border-basePrimaryDark">
                        <A
                          href={`/shop-by-occassion/gift-card-for-${formatGroup(occasion)}`}
                        >
                          {occasion}
                        </A>
                      </span>
                    )}
                  </For>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
